import React from "react"
import styles from "./section.module.css"

export default ({ sectionTitle, children }) => {
    if (sectionTitle) {
        return (
            <div className={styles.section}>
                <h2>{sectionTitle}</h2>
                {children}
            </div>
        );
    } else {
        return (
            <div className={styles.section}>
                {children}
            </div>
        );
    }
}
