import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, pathname }) => (
    <StaticQuery 
        query={query} 
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                }
            }
        }) => {
            const seo = {
                title: title || defaultTitle,
                titleTemplate: title ? titleTemplate : null,
                description: description || defaultDescription,
                image: `${siteUrl}${image || defaultImage}`,
                url: `${siteUrl}${pathname || '/'}`,
            };
            
            return (
                <>
                  <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
                    <meta name="description" content={seo.description} />
                    <meta name="image" content={seo.image} />
                    {seo.url && <meta property="og:url" content={seo.url} />}
                    {seo.title && <meta property="og:title" content={seo.title} />}
                    {seo.description && (
                      <meta property="og:description" content={seo.description} />
                    )}
                    {seo.image && <meta property="og:image" content={seo.image} />}
                    <meta name="twitter:card" content="summary_large_image" />
                    {seo.title && <meta name="twitter:title" content={seo.title} />}
                    {seo.description && (
                      <meta name="twitter:description" content={seo.description} />
                    )}
                    {seo.image && <meta name="twitter:image" content={seo.image} />}
                  </Helmet>
                </>
            );
        }}
    />
)

export default SEO;

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                defaultImage: image
            }
        }
    }
`;
